<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="listaSitio">Tipo de sitio</label>
          <select
            class="form-control form-control-sm"
            v-model="form.sitio_type"
            :class="$v.form.sitio_type.$invalid ? 'is-invalid' : 'is-valid'"
            @change="resetSitios()"
          >
            <option value="">Seleccione...</option>
            <option
              v-for="listaSitio in listasForms.listaSitios"
              :key="listaSitio.numeracion"
              :value="listaSitio.numeracion"
            >
              {{ listaSitio.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div v-if="form.sitio_type == 1" class="form-group">
          <label for="ciudad">Ciudad</label>
          <v-select
            :class="[
              $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="ciudad"
            placeholder="ciudad"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.ciudades"
            :filterable="false"
            @search="buscarCiudades"
            @input="selectCiudad()"
          ></v-select>
        </div>
        <div v-if="form.sitio_type == 2" class="form-group">
          <label for="geocerca_zona">Geocerca Zona</label>
          <v-select
            :class="[
              $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="geocerca_zona"
            placeholder="Geocerca Zona"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.geocercas_zonas"
            :filterable="false"
            @search="buscarGeocercas"
            @input="selectGeocercas()"
          ></v-select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="tiempo">Tiempo (Minutos)</label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="tiempo"
            min="0"
            v-model="form.tiempo"
            :class="$v.form.tiempo.$invalid ? 'is-invalid' : 'is-valid'"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="orden">Orden</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="orden"
            disabled="true"
            v-model="form.orden"
            :class="$v.form.orden.$invalid ? 'is-invalid' : 'is-valid'"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <br />
          <button
            v-show="!$v.form.$invalid"
            type="button"
            @click="cargar()"
            class="btn btn-success"
            data-html="true"
            title="Guardar punto de gestion"
            data-toggle="tooltip"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div id="tabla puntos">
      <div class="card-body p-0">
        <div class="col-md-12 form-group">
          <table
            id="tabla-rutas"
            class="table table-bordered table-striped table-hover table-sm"
          >
            <thead>
              <tr>
                <th style="width: 100px">Orden</th>
                <th style="width: 200px">Tipo de sitio</th>
                <th>Sitio</th>
                <th style="width: 100px">Tiempo</th>
                <th style="width: 100px">Servicios</th>
                <th style="width: 100px">
                  Orden
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rutaSitio, index) in rutasSitio"
                :key="rutaSitio.posicion"
              >
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="orden"
                      v-model="form2.orden"
                      :class="
                        $v.form2.orden.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.pivot.orden }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <select
                      class="form-control form-control-sm"
                      v-model="form2.sitio_type"
                      :class="
                        $v.form2.sitio_type.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @change="resetSitios()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="listaSitio in listasForms.listaSitios"
                        :key="listaSitio.numeracion"
                        :value="listaSitio.numeracion"
                      >
                        {{ listaSitio.descripcion }}
                      </option>
                    </select>
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{
                      rutaSitio.pivot.sitio_type == "App\\Ciudad"
                        ? "Ciudad"
                        : "Geocerca Zona"
                    }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <div v-if="form2.sitio_type == 1" class="form-group">
                      <v-select
                        :class="[
                          $v.form2.sitio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="ciudad"
                        placeholder="Ciudad"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.ciudades"
                        :filterable="false"
                        @search="buscarCiudades"
                        @input="selectCiudad()"
                      >
                      </v-select>
                    </div>
                    <div v-if="form2.sitio_type == 2" class="form-group">
                      <v-select
                        :class="[
                          $v.form2.sitio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="geocerca_zona"
                        placeholder="Geocerca Zona"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.geocercas_zonas"
                        :filterable="false"
                        @search="buscarGeocercas"
                        @input="selectGeocercas()"
                      ></v-select>
                    </div>
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.nombre }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="tiempo"
                      v-model="form2.tiempo"
                      :class="
                        $v.form2.tiempo.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.pivot.tiempo }}
                  </span>
                </td>
                <td class="col-sm-2 text-right">
                  <span v-if="formActualizar && idActualizar == index">
                    <button
                      v-show="!$v.form2.$invalid"
                      type="button"
                      class="btn btn-sm bg-success"
                      @click="guardarActualizacion(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </span>
                  <span v-else>
                    <!-- Botón para mostrar el formulario de actualizar -->
                    <button
                      type="button"
                      class="btn btn-sm bg-navy"
                      @click="verFormActualizar(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <!-- Botón para borrar -->
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="destroy(index)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </span>
                </td>
                <td class="text-right">
                  <button
                    class="btn btn-sm btn-success"
                    v-if="index + 1 != 1"
                    @click="subirOrden(index)"
                  >
                    <i class="fas fa-arrow-up"></i></button
                  ><button
                    class="btn btn-sm btn-danger"
                    v-if="index + 1 != rutasSitio.length"
                    @click="bajarOrden(index)"
                  >
                    <i class="fas fa-arrow-down"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 form-group text-right">
          <button
            type="button"
            title="Guardar Cambios"
            class="btn btn-sm bg-primary"
            @click="save()"
          >
            <i class="fas fa-save"></i>
            <div>
              <span><small>Guardar Cambios</small></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "rutaSitio",
  components: {
    vSelect,
  },
  data() {
    return {
      form: { sitio_id: null, orden: null, sitio_type: null },
      form2: { sitio_id: null, orden: null, tiempo: null },
      idActual: null,
      idAnterior: null,
      idSuperior: null,
      nombre_ciudad: null,
      nombre_geocerca: null,
      formActualizar: false,
      ciudadesAsignados: [],
      GeocercasAsignados: [],
      idActualizar: -1,
      listaOrden: [],
      ciudad: [],
      geocerca_zona: [],
      tep_ruta_id: null,
      rutasSitio: {},
      listasForms: {
        ciudades: [],
        geocercas_zonas: [],
      },
    };
  },
  validations: {
    form: {
      sitio_type: {
        required,
      },
      sitio_id: {
        required,
      },
      tiempo: {
        required,
      },
      orden: {
        required,
      },
    },
    form2: {
      sitio_type: {
        required,
      },
      sitio_id: {
        required,
      },
      tiempo: {
        required,
      },
      orden: {
        required,
      },
    },
  },
  methods: {
    getIndex() {
      this.form.tep_ruta_id = this.$route.params.id;
      this.tep_ruta_id = this.form.tep_ruta_id;
      axios
        .get("/api/tep/rutas/show/" + this.form.tep_ruta_id)
        .then((response) => {
          this.rutasSitio = response.data;
          this.form.orden = this.rutasSitio.length + 1;
          this.getAsignados();
        });
    },

    // Se consulta los sitios y puntos de gestión ya asignados
    getAsignados() {
      this.ciudadesAsignados = [];
      this.GeocercasAsignados = [];
      this.rutasSitio.forEach((element) => {
        if (element.pivot.sitio_type == "App\\GeocercaZona") {
          this.GeocercasAsignados.push(element.pivot.sitio_id);
        } else {
          this.ciudadesAsignados.push(element.pivot.sitio_id);
        }
      });
    },

    // Consulto los tipos de sitio
    getListaSitio() {
      axios.get("/api/lista/94").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },

    // Se busca los sitios disponibles
    buscarCiudades(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/ciudades/lista?ciudad=" + search;
        axios
          .get(url, {
            params: {
              estado: 1,
              not_id: this.ciudadesAsignados,
            },
          })
          .then(function(response) {
            me.listasForms.ciudades = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectCiudad() {
      this.form.sitio_id = "";
      this.form2.sitio_id = "";
      if (this.ciudad) {
        this.form.sitio_id = this.ciudad.id;
        this.form2.sitio_id = this.ciudad.id;
        this.nombre_ciudad = this.ciudad.nombre;
      }
      this.validarSitio();
    },

    // Se busca los puntos de gestión disponibles
    buscarGeocercas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/basico/geocercaZona/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              estado: 1,
              not_id: this.GeocercasAsignados,
            },
          })
          .then(function(response) {
            me.listasForms.geocercas_zonas = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectGeocercas() {
      this.form.sitio_id = "";
      this.form2.sitio_id = "";
      if (this.geocerca_zona) {
        this.form.sitio_id = this.geocerca_zona.id;
        this.form2.sitio_id = this.geocerca_zona.id;
        this.nombre_geocerca = this.geocerca_zona.nombre;
      }
      this.validarSitio();
    },

    resetSitios() {
      this.form.sitio_id = null;
      this.form2.sitio_id = null;
      this.ciudad = [];
      this.listasForms.ciudades = [];
      this.geocerca_zona = [];
      this.listasForms.geocercas_zonas = [];
    },

    cargar() {
      if (!this.$v.form.$invalid) {
        if (this.form.sitio_type == 1) {
          this.form.sitio_type = "App\\Ciudad";
        } else {
          this.form.sitio_type = "App\\GeocercaZona";
        }
        if (this.form.sitio_type == "App\\Ciudad") {
          this.rutasSitio.push({
            pivot: this.form,
            nombre: this.nombre_ciudad,
            posicion: this.form.orden,
          });
          this.form = {
            orden: this.rutasSitio.length + 1,
            tep_ruta_id: this.tep_ruta_id,
            sitio_id: null,
          };
        } else {
          this.rutasSitio.push({
            pivot: this.form,
            nombre: this.nombre_geocerca,
            posicion: this.form.orden,
          });
          this.form = {
            orden: this.rutasSitio.length + 1,
            tep_ruta_id: this.tep_ruta_id,
            sitio_id: null,
          };
        }
        this.getAsignados();
      }
    },

    save() {
      axios
        .put("/api/tep/rutas/assingOrRevokeSitio", {
          tep_ruta_id: this.tep_ruta_id,
          arreglo: this.rutasSitio,
        })
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se actualizaron los datos de la ruta exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    verFormActualizar: function(ruta_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizar = ruta_id;
      this.form2.orden = this.rutasSitio[ruta_id].pivot.orden;
      this.form2.sitio_id = this.rutasSitio[ruta_id].pivot.sitio_id;
      if (this.rutasSitio[ruta_id].pivot.sitio_type == "App\\Ciudad") {
        this.form2.sitio_type = 1;
        this.sitio = {
          id: this.rutasSitio[ruta_id].pivot.sitio_id,
          nombre: this.rutasSitio[ruta_id].nombre,
        };
      } else {
        this.form2.sitio_type = 2;
        this.punto_gestion = {
          id: this.rutasSitio[ruta_id].pivot.sitio_id,
          nombre: this.rutasSitio[ruta_id].nombre,
        };
      }
      this.form2.tiempo = this.rutasSitio[ruta_id].pivot.tiempo;
      // Mostramos el formulario
      this.formActualizar = true;
    },

    guardarActualizacion: function(ruta_id) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizar = false;
      // Actualizamos los datos
      this.rutasSitio[ruta_id].pivot.orden = this.form2.orden;
      this.rutasSitio[ruta_id].pivot.sitio_id = this.form2.sitio_id;
      if (this.form2.sitio_type == 1) {
        this.rutasSitio[ruta_id].pivot.sitio_type = "App\\Ciudad";
        this.rutasSitio[ruta_id].nombre = this.ciudad.nombre;
      } else {
        this.rutasSitio[ruta_id].pivot.sitio_type = "App\\GeocercaZona";
        this.rutasSitio[ruta_id].nombre = this.geocerca_zona.nombre;
      }
      this.rutasSitio[ruta_id].pivot.tiempo = this.form2.tiempo;
      this.rutasSitio[ruta_id].posicion = this.form2.orden;
    },

    destroy: function(ruta_id) {
      this.$swal({
        title: "Está seguro de quitar el este sitio de la Ruta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          // Borramos de la lista
          let rutica = ruta_id + 1;
          for (let i = rutica; i < this.rutasSitio.length; i++) {
            this.rutasSitio[i].pivot.orden = this.rutasSitio[i].pivot.orden - 1;
          }
          this.rutasSitio.splice(ruta_id, 1);
          this.form.orden = this.rutasSitio.length + 1;
        }
      });
    },
    subirOrden(index) {
      let orden = index + 1;
      const item_actual = this.rutasSitio[index].pivot;
      const item_anterior = this.rutasSitio[index - 1].pivot;
      // Se ajusta el arreglo para actualizar el orden
      const params = { item_actual, item_anterior };
      this.actualizaOrden(params);
    },
    bajarOrden(index) {
      let orden = index + 1;
      const item_actual = this.rutasSitio[index].pivot;
      const item_siguiente = this.rutasSitio[index + 1].pivot;
      // Se ajusta el arreglo para actualizar el orden
      const params = { item_actual, item_siguiente };
      this.actualizaOrden(params);
    },
    actualizaOrden(params) {
      axios({
        method: "PUT",
        url: "/api/tep/rutas/ordenarItems",
        data: params,
      })
        .then(() => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se ordenó el Item de Inspección de manera correcta",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validarSitio() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tep/rutas/valRutSitio",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        if (this.validar == "si") {
          this.$swal({
            icon: "error",
            title: `Ya existe este Sitio para esta Ruta`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.sitio_id = null;
          this.ciudad = [];
          this.form.sitio_type = null;
          this.geocerca_zona = [];
          this.cargando = false;
        }
      });
    },
  },

  mounted() {
    this.getListaSitio();
    this.getIndex();
  },
};
</script>
